export const environment = {
    production: false,
    urls: {
        api: 'https://sdp.n2g-iona-staging.net/v2/',
        auth: 'https://iona-test-staging.wla-backend.de/auth',
        faq: 'https://iona-dev.wla-backend.de/faq/iona/?showNav=true',
        rewriteUrl: 'https://eona-test.wla-backend.de/demo/',
        userGroup: 'https://ews.n2g-iona-staging.net/api',
    },
    isStaging: true,
};


